/*
TO DO
Change share() variables
*/


import React, {useState, useCallback} from 'react';
//import ReactDOM from "react-dom";
//import Axios from "axios";
//import { BrowserRouter as HashRouter, Route, Router } from "react-router-dom";
import {
  AppProvider,
  Button,
  Card,
  Checkbox,
  ChoiceList,
  ColorPicker,
  DataTable,
  DatePicker,
  DisplayText,
  FormLayout,
  Frame,
  Heading,
  hsbToRgb,
  Layout,
  Page,
  RangeSlider,
  rgbToHex,
  Select,
  Spinner,
  Stack,
  TextField,
  TextStyle,
} from '@shopify/polaris';
//import {ImportMinor} from '@shopify/polaris-icons';
import './App.css';
import '@shopify/polaris/dist/styles.css';


//globals
const ajaxUrl = 'https://dakine-reporting.immersiveecommerce.com/ajax.php';
var urlParams = new URLSearchParams(window.location.search);
var storeUrl = urlParams.get('shop');

//date warning
const dateWarningThreshold = 60 * 60 * 24 * 32 * 1000; //greater than a month

//data
const rows = [
  [ 'Sale transaction count',0,0,0,'Total number of sale transactions' ],
  [ 'Discounted merchandise revenue',0,0,0,'The total charged to customers for merchandise, after all markdowns, discounts, promotions applied (what the customer was actually charged for the merchandise)' ],
  [ 'Shipping revenue',0,0,0,'The total charged to customers for shipping' ],
  [ 'Tax revenue',0,0,0,'The total charged to customers for tax' ],
  [ 'Base price value of goods sold',0,0,0,'The total MSRP value of all goods sold during period' ],
  [ 'Wholesale cost of goods sold',0,0,0,'<s>The total Marquee wholesale cost of goods sold (book wholesale minus 30%)</s>' ],
  //[ 'Gross sales revenue',0,0,0,'Base price value of goods sold + shipping revenue + tax revenue' ],
  [ 'Total sales discounts',0,0,0,'Base price value of goods sold - discounted merchandise revenue' ],
  [ 'Credit transaction count',0,0,0,'Total number of credit transactions' ],
  //[ 'Discounted merchandise credits',0,0,0,'The total discounted merchandise charges credited back to customers' ],
  //[ 'Shipping credits',0,0,0,'The total shipping charges credited back to customers' ],
  //[ 'Tax credits',0,0,0,'The total tax charges credited back to customers' ],
  //[ 'Credit adjustments',0,0,0,'Non-merchandise, shipping, or tax credits' ],
  [ 'Base price value of goods returned',0,0,0,'The total MSRP value of all goods returned (credited) during period' ],
  [ 'Wholesale cost of goods returned',0,0,0,'The total Marquee wholesale value (book wholesale minus 30%) of goods returned (credited)' ],
  [ 'Total credit discounts',0,0,0,'Base price value of goods returned - discounted merchandise credits' ],
  [ 'Total credit issued',0,0,0,'Discounted merchandise credits + shipping credits + tax credits + credit adjustments' ],
  [ 'Net discounted merchandise revenue',0,0,0,'Discounted merchandise revenue - discounted merchandise credits' ],
  [ 'Net shipping revenue',0,0,0,'Shipping revenue - shipping credits' ],
  [ 'Net tax revenue',0,0,0,'Tax revenue - tax credits' ],
  [ 'Net sales revenue',0,0,0,'(Discounted merchandise revenue - discounted merchandise credits) + (shipping revenue - shipping credits) + (tax revenue - tax credits) - (credit adjustments)' ],
  [ 'Net discounts',0,0,0,'(Total sales discounts) - (total credit discounts)' ],
  [ 'Average order value, gross merchandise sales',0,0,0,'(base price value of goods sold) / (sale transaction count)' ],
  [ 'Average order value, discounted merchandise',0,0,0,'Discounted merchandise revenue / sale transaction count' ],
  [ 'Sale units count',0,0,0,'total number of items purchased' ],
  [ 'Units Per Transaction',0,0,0,'sale units count / sale transaction count' ],
  [ 'Net gross margin, merchandise and shipping',0,0,0,'((Net discounted merchandise revenue) + (Net shipping revenue)) – ((Wholesale cost of goods sold) – (Wholesale cost of goods returned))' ],
  [ 'Net product margin',0,0,0,'(Net discounted merchandise revenue) - ((Wholesale cost of goods sold) – (Wholesale cost of goods returned))' ],
];

var dateObj = new Date();
var oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
//new Date().toLocaleString().split(',')[0]
//new Date().getDate() - 7.toLocaleString().split(',')[0]


class App extends React.Component {

  constructor(props) {
    //general stuff
    super(props);


    /*=================================================================
    //
    // BINDINGS
    //
    =================================================================*/

    this.changeBeginDate = this.changeBeginDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.toggleDebug = this.toggleDebug.bind(this);

    //eof bindings

    /*=================================================================
    //
    // STATE
    //
    {"app":{"enabled":"false"},"widget_color":{"button_background":"#ffffff","button_icon":"#000000"},"mobile_position":{"vertical":"bottom","horizontal":"right","x_offset":"40","y_offset":"40"},"desktop_position":{"vertical":"bottom","horizontal":"right","x_offset":"40","y_offset":"40"}}   
    =================================================================*/
    this.state = {
      orderId:'',
      month:dateObj.getUTCMonth(),
      year:dateObj.getUTCFullYear(),
      showLongQueryWarning:false,
      selectedDates:{
        start: oneWeekAgo,
        end: dateObj
      },
      formDateBegin:oneWeekAgo.toLocaleString().split(',')[0],
      formDateEnd:dateObj.toLocaleString().split(',')[0],
      formDateBeginIsValid:true,
      formDateEndIsValid:true,
      isSelectingDate:false,
      isRunningQuery: false,
      data:{
        transactions:{
          total:0,
          US:0,
          CA:0
        }
      },
      debug:false,
      debugOutput:'No output requested',
      rows: [
        [ 'Sale transaction count',0,0,0,0,0,0,0,0,0,'Total number of sale transactions' ],
        [ 'Discounted merchandise revenue',0,0,0,0,0,0,0,0,0,'The total charged to customers for merchandise, after all markdowns, discounts, promotions applied (what the customer was actually charged for the merchandise)' ],
        [ 'Shipping revenue',0,0,0,0,0,0,0,0,0,'The total charged to customers for shipping' ],
        [ 'Tax revenue',0,0,0,0,0,0,0,0,0,'The total charged to customers for tax' ],
        [ 'Base price value of goods sold',0,0,0,0,0,0,0,0,0,'The total MSRP value of all goods sold during period' ],
        [ 'Wholesale cost of goods sold',0,0,0,0,0,0,0,0,0,'<s>The total Marquee wholesale cost of goods sold (book wholesale minus 30%)</s>' ],
        //[ 'Gross sales revenue',0,0,0,0,0,0,0,0,0,'Base price value of goods sold + shipping revenue + tax revenue' ],
        [ 'Total sales discounts',0,0,0,0,0,0,0,0,0,'Base price value of goods sold - discounted merchandise revenue' ],
        [ 'Credit transaction count',0,0,0,0,0,0,0,0,0,'Total number of credit transactions' ],
        //[ 'Discounted merchandise credits',0,0,0,0,0,0,0,0,0,'The total discounted merchandise charges credited back to customers' ],
        //[ 'Shipping credits',0,0,0,0,0,0,0,0,0,'The total shipping charges credited back to customers' ],
        //[ 'Tax credits',0,0,0,0,0,0,0,0,0,'The total tax charges credited back to customers' ],
        //[ 'Credit adjustments',0,0,0,0,0,0,0,0,0,'Non-merchandise, shipping, or tax credits' ],
        [ 'Base price value of goods returned',0,0,0,0,0,0,0,0,0,'The total MSRP value of all goods returned (credited) during period' ],
        [ 'Wholesale cost of goods returned',0,0,0,0,0,0,0,0,0,'The total Marquee wholesale value (book wholesale minus 30%) of goods returned (credited)' ],
        [ 'Total credit discounts',0,0,0,0,0,0,0,0,0,'Base price value of goods returned - discounted merchandise credits' ],
        [ 'Total credit issued',0,0,0,0,0,0,0,0,0,'Discounted merchandise credits + shipping credits + tax credits + credit adjustments' ],
        [ 'Net discounted merchandise revenue',0,0,0,0,0,0,0,0,0,'Discounted merchandise revenue - discounted merchandise credits' ],
        [ 'Net shipping revenue',0,0,0,0,0,0,0,0,0,'Shipping revenue - shipping credits' ],
        [ 'Net tax revenue',0,0,0,0,0,0,0,0,0,'Tax revenue - tax credits' ],
        [ 'Net sales revenue',0,0,0,0,0,0,0,0,0,'(Discounted merchandise revenue - discounted merchandise credits) + (shipping revenue - shipping credits) + (tax revenue - tax credits) - (credit adjustments)' ],
        [ 'Net discounts',0,0,0,0,0,0,0,0,0,'(Total sales discounts) - (total credit discounts)' ],
        [ 'Average order value, gross merchandise sales',0,0,0,0,0,0,0,0,0,'(base price value of goods sold) / (sale transaction count)' ],
        [ 'Average order value, discounted merchandise',0,0,0,0,0,0,0,0,0,'Discounted merchandise revenue / sale transaction count' ],
        [ 'Sale units count',0,0,0,0,0,0,0,0,0,'total number of items purchased' ],
        [ 'Units Per Transaction',0,0,0,0,0,0,0,0,0,'sale units count / sale transaction count' ],
        [ 'Net gross margin, merchandise and shipping',0,0,0,0,0,0,0,0,0,'((Net discounted merchandise revenue) + (Net shipping revenue)) – ((Wholesale cost of goods sold) – (Wholesale cost of goods returned))' ],
        [ 'Net product margin',0,0,0,0,0,0,0,0,0,'(Net discounted merchandise revenue) - ((Wholesale cost of goods sold) – (Wholesale cost of goods returned))' ],
      ],
      orderAdjustmentsArray:[]
    }    
    //eof STATE  
 
  } //eof constructor


  componentWillMount(){
    //this.getSettings();
  }

  //eof lifecyle


  //data

  handleMonthChange = (month,year) => {
    //console.log(month+'/'+year);
    this.setState({
      month: month,
      year:year
    },() => {
      //console.log('done');
    });
  }
  showDatePicker() {
    this.setState({
      isSelectingDate:true
    },() => {
      //console.log('done');
    });    
  }

  changeOrderId = (value) => {
    this.setState({
      orderId: value,
    });
  }

  changeBeginDate = (value) => {
    if(Date.parse(value) < 1){
      var isValid = false;
    } else {
      var isValid = true;
    }
    this.setState({
      formDateBegin: value,
      formDateBeginIsValid: isValid
    },() => {
      console.log('b: '+Date.parse(value)+' e: '+Date.parse(this.state.formDateEnd)+ ' d: '+(Date.parse(this.state.formDateEnd) - Date.parse(value)));
      if(
        (Date.parse(this.state.formDateEnd) - Date.parse(value))
        >
        dateWarningThreshold
      ) {
        console.log('long query warning');
        this.setState({showLongQueryWarning:true});
      } else {
        this.setState({showLongQueryWarning:false});
      }
    });    
  }
  changeEndDate = (value) => {
    if(Date.parse(value) < 1){
      var isValid = false;
    } else {
      var isValid = true;
    }
    this.setState({
      formDateEnd: value,
      formDateBeginIsValid: isValid
    },() => {
      console.log('b: '+Date.parse(this.state.formDateBegin)+' e: '+Date.parse(value)+ ' d: '+(Date.parse(value) - Date.parse(this.state.formDateBegin)));
      if(
        (Date.parse(value) - Date.parse(this.state.formDateBegin))
        >
        dateWarningThreshold
      ) {
        console.log('long query warning');
        this.setState({showLongQueryWarning:true});
      } else {
        this.setState({showLongQueryWarning:false});
      }
    });    
  }
  toggleDebug(event) {
    if(this.state.debug){
      this.setState({debug:false});
    } else {
      this.setState({debug:true});
    }
  }
  runQuery() {
    console.log('running query');
    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();
    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      var json = JSON.parse(xhr.responseText);
      console.log(xhr.responseText);
      let rows = this.state.rows;
      //sales tranasctions count
      rows[0][1] = json.data.transactions.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      rows[0][2] = json.data.transactions.US.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      rows[0][3] = json.data.transactions.CA.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      rows[0][4] = json.data.transactions.pro.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      rows[0][5] = json.data.transactions.employee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      rows[0][6] = json.data.transactions.warranty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      rows[0][7] = json.data.transactions.expertvoice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      rows[0][8] = json.data.transactions.retail.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      rows[0][9] = json.data.transactions.co2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //discounted merchandise rev
      rows[1][1] = '$'+json.data.discounted_merchandise_revenue.total;
      rows[1][2] = '$'+json.data.discounted_merchandise_revenue.US;
      rows[1][3] = '$'+json.data.discounted_merchandise_revenue.CA;
      rows[1][4] = '$'+json.data.discounted_merchandise_revenue.pro;
      rows[1][5] = '$'+json.data.discounted_merchandise_revenue.employee;
      rows[1][6] = '$'+json.data.discounted_merchandise_revenue.warranty;
      rows[1][7] = '$'+json.data.discounted_merchandise_revenue.expertvoice;
      rows[1][8] = '$'+json.data.discounted_merchandise_revenue.retail;
      rows[1][9] = '$'+json.data.discounted_merchandise_revenue.co2;

      //shipping rev
      rows[2][1] = '$'+json.data.shipping_revenue.total;
      rows[2][2] = '$'+json.data.shipping_revenue.US;
      rows[2][3] = '$'+json.data.shipping_revenue.CA;
      rows[2][4] = '$'+json.data.shipping_revenue.pro;
      rows[2][5] = '$'+json.data.shipping_revenue.employee;
      rows[2][6] = '$'+json.data.shipping_revenue.warranty;
      rows[2][7] = '$'+json.data.shipping_revenue.expertvoice;
      rows[2][8] = '$'+json.data.shipping_revenue.retail;
      rows[2][9] = '$'+json.data.shipping_revenue.co2;

      //tax rev
      rows[3][1] = '$'+json.data.tax_revenue.total;
      rows[3][2] = '$'+json.data.tax_revenue.US;
      rows[3][3] = '$'+json.data.tax_revenue.CA;
      rows[3][4] = '$'+json.data.tax_revenue.pro;
      rows[3][5] = '$'+json.data.tax_revenue.employee;
      rows[3][6] = '$'+json.data.tax_revenue.warranty;
      rows[3][7] = '$'+json.data.tax_revenue.expertvoice;
      rows[3][8] = '$'+json.data.tax_revenue.retail;
      rows[3][9] = '$'+json.data.tax_revenue.co2;

      //Base price value of goods sold
      rows[4][1] = '$'+json.data.base_price_value_goods_sold.total;
      rows[4][2] = '$'+json.data.base_price_value_goods_sold.US;
      rows[4][3] = '$'+json.data.base_price_value_goods_sold.CA;  
      rows[4][4] = '$'+json.data.base_price_value_goods_sold.pro;
      rows[4][5] = '$'+json.data.base_price_value_goods_sold.employee;
      rows[4][6] = '$'+json.data.base_price_value_goods_sold.warranty;
      rows[4][7] = '$'+json.data.base_price_value_goods_sold.expertvoice;
      rows[4][8] = '$'+json.data.base_price_value_goods_sold.retail;
      rows[4][9] = '$'+json.data.base_price_value_goods_sold.co2;
      
      //Wholesale cost of goods sold
      rows[5][1] = '$'+json.data.cogs.total;
      rows[5][2] = '$'+json.data.cogs.US;
      rows[5][3] = '$'+json.data.cogs.CA;
      rows[5][4] = '$'+json.data.cogs.pro;
      rows[5][5] = '$'+json.data.cogs.employee;
      rows[5][6] = '$'+json.data.cogs.warranty;
      rows[5][7] = '$'+json.data.cogs.expertvoice;
      rows[5][8] = '$'+json.data.cogs.retail;
      rows[5][9] = '$'+json.data.cogs.co2;

      //Gross sales revenue
      /*
      rows[6][1] = '$'+json.data.gross_sales_revenue.total;
      rows[6][2] = '$'+json.data.gross_sales_revenue.US;
      rows[6][3] = '$'+json.data.gross_sales_revenue.CA;
      rows[6][4] = '$'+json.data.gross_sales_revenue.pro;
      rows[6][5] = '$'+json.data.gross_sales_revenue.employee;
      rows[6][6] = '$'+json.data.gross_sales_revenue.warranty;
      rows[6][7] = '$'+json.data.gross_sales_revenue.expertvoice;
      rows[6][8] = '$'+json.data.gross_sales_revenue.retail;
      rows[6][9] = '$'+json.data.gross_sales_revenue.co2;
      */

      //Total sales discounts
      rows[6][1] = '$'+json.data.total_sales_discounts.total;
      rows[6][2] = '$'+json.data.total_sales_discounts.US;
      rows[6][3] = '$'+json.data.total_sales_discounts.CA;
      rows[6][4] = '$'+json.data.total_sales_discounts.pro;
      rows[6][5] = '$'+json.data.total_sales_discounts.employee;
      rows[6][6] = '$'+json.data.total_sales_discounts.warranty;
      rows[6][7] = '$'+json.data.total_sales_discounts.expertvoice;
      rows[6][8] = '$'+json.data.total_sales_discounts.retail;
      rows[6][9] = '$'+json.data.total_sales_discounts.co2;

      //Credit transaction count
      rows[7][1] = json.data.refunds.total;
      rows[7][2] = json.data.refunds.US;
      rows[7][3] = json.data.refunds.CA;
      rows[7][4] = json.data.refunds.pro;
      rows[7][5] = json.data.refunds.employee;
      rows[7][6] = json.data.refunds.warranty;
      rows[7][7] = json.data.refunds.expertvoice;
      rows[7][8] = json.data.refunds.retail;
      rows[7][9] = json.data.refunds.co2;

      //discounted_merchant_credits
      /*
      rows[8][1] = '$'+json.data.discounted_merchant_credits.total;
      rows[8][2] = '$'+json.data.discounted_merchant_credits.US;
      rows[8][3] = '$'+json.data.discounted_merchant_credits.CA;
      rows[8][4] = '$'+json.data.discounted_merchant_credits.pro;
      rows[8][5] = '$'+json.data.discounted_merchant_credits.employee;
      rows[8][6] = '$'+json.data.discounted_merchant_credits.warranty;
      rows[8][7] = '$'+json.data.discounted_merchant_credits.expertvoice;
      rows[8][8] = '$'+json.data.discounted_merchant_credits.retail;
      rows[8][9] = '$'+json.data.discounted_merchant_credits.co2;
      */

      //shipping_credits
      /*
      rows[9][1] = '$'+json.data.shipping_credits.total;
      rows[9][2] = '$'+json.data.shipping_credits.US;
      rows[9][3] = '$'+json.data.shipping_credits.CA;
      rows[9][4] = '$'+json.data.shipping_credits.pro;
      rows[9][5] = '$'+json.data.shipping_credits.employee;
      rows[9][6] = '$'+json.data.shipping_credits.warranty;
      rows[9][7] = '$'+json.data.shipping_credits.expertvoice;
      rows[9][8] = '$'+json.data.shipping_credits.retail;
      rows[9][9] = '$'+json.data.shipping_credits.co2;
      */

      //tax_credits
      /*
      rows[10][1] = '$'+json.data.tax_credits.total;
      rows[10][2] = '$'+json.data.tax_credits.US;
      rows[10][3] = '$'+json.data.tax_credits.CA;
      rows[10][4] = '$'+json.data.tax_credits.pro;
      rows[10][5] = '$'+json.data.tax_credits.employee;
      rows[10][6] = '$'+json.data.tax_credits.warranty;
      rows[10][7] = '$'+json.data.tax_credits.expertvoice;
      rows[10][8] = '$'+json.data.tax_credits.retail;
      rows[10][9] = '$'+json.data.tax_credits.co2;
      */

      //Credit adjustments
      /*
      rows[11][1] = '$'+json.data.credit_adjustments.total;
      rows[11][2] = '$'+json.data.credit_adjustments.US;
      rows[11][3] = '$'+json.data.credit_adjustments.CA;
      rows[11][4] = '$'+json.data.credit_adjustments.pro;
      rows[11][5] = '$'+json.data.credit_adjustments.employee;
      rows[11][6] = '$'+json.data.credit_adjustments.warranty;
      rows[11][7] = '$'+json.data.credit_adjustments.expertvoice;
      rows[11][8] = '$'+json.data.credit_adjustments.retail;
      rows[11][9] = '$'+json.data.credit_adjustments.co2;
      */

      //Base price value of goods returned
      rows[8][1] = '$'+json.data.base_price_value_of_goods_returned.total;
      rows[8][2] = '$'+json.data.base_price_value_of_goods_returned.US;
      rows[8][3] = '$'+json.data.base_price_value_of_goods_returned.CA;
      rows[8][4] = '$'+json.data.base_price_value_of_goods_returned.pro;
      rows[8][5] = '$'+json.data.base_price_value_of_goods_returned.employee;
      rows[8][6] = '$'+json.data.base_price_value_of_goods_returned.warranty;
      rows[8][7] = '$'+json.data.base_price_value_of_goods_returned.expertvoice;
      rows[8][8] = '$'+json.data.base_price_value_of_goods_returned.retail;
      rows[8][9] = '$'+json.data.base_price_value_of_goods_returned.co2;

      //Wholesale cost of goods returned
      rows[9][1] = '$'+json.data.cogs_returned.total;
      rows[9][2] = '$'+json.data.cogs_returned.US;
      rows[9][3] = '$'+json.data.cogs_returned.CA;
      rows[9][4] = '$'+json.data.cogs_returned.pro;
      rows[9][5] = '$'+json.data.cogs_returned.employee;
      rows[9][6] = '$'+json.data.cogs_returned.warranty;
      rows[9][7] = '$'+json.data.cogs_returned.expertvoice;
      rows[9][8] = '$'+json.data.cogs_returned.retail;
      rows[9][9] = '$'+json.data.cogs_returned.co2;

      //Total credit discounts
      rows[10][1] = '$'+json.data.total_credit_discounts.total;
      rows[10][2] = '$'+json.data.total_credit_discounts.US;
      rows[10][3] = '$'+json.data.total_credit_discounts.CA;
      rows[10][4] = '$'+json.data.total_credit_discounts.pro;
      rows[10][5] = '$'+json.data.total_credit_discounts.employee;
      rows[10][6] = '$'+json.data.total_credit_discounts.warranty;
      rows[10][7] = '$'+json.data.total_credit_discounts.expertvoice;
      rows[10][8] = '$'+json.data.total_credit_discounts.retail;
      rows[10][9] = '$'+json.data.total_credit_discounts.co2;

      //Total credit issued
      rows[11][1] = '$'+json.data.total_credits_issued.total;
      rows[11][2] = '$'+json.data.total_credits_issued.US;
      rows[11][3] = '$'+json.data.total_credits_issued.CA;
      rows[11][4] = '$'+json.data.total_credits_issued.pro;
      rows[11][5] = '$'+json.data.total_credits_issued.employee;
      rows[11][6] = '$'+json.data.total_credits_issued.warranty;
      rows[11][7] = '$'+json.data.total_credits_issued.expertvoice;
      rows[11][8] = '$'+json.data.total_credits_issued.retail;
      rows[11][9] = '$'+json.data.total_credits_issued.co2;

      //Net discounted merchandise revenue
      rows[12][1] = '$'+json.data.net_discounted_merchandise_revenue.total;
      rows[12][2] = '$'+json.data.net_discounted_merchandise_revenue.US;
      rows[12][3] = '$'+json.data.net_discounted_merchandise_revenue.CA;
      rows[12][4] = '$'+json.data.net_discounted_merchandise_revenue.pro;
      rows[12][5] = '$'+json.data.net_discounted_merchandise_revenue.employee;
      rows[12][6] = '$'+json.data.net_discounted_merchandise_revenue.warranty;
      rows[12][7] = '$'+json.data.net_discounted_merchandise_revenue.expertvoice;
      rows[12][8] = '$'+json.data.net_discounted_merchandise_revenue.retail;
      rows[12][9] = '$'+json.data.net_discounted_merchandise_revenue.co2;

      //Net shipping revenue
      rows[13][1] = '$'+json.data.net_shipping_revenue.total;
      rows[13][2] = '$'+json.data.net_shipping_revenue.US;
      rows[13][3] = '$'+json.data.net_shipping_revenue.CA;
      rows[13][4] = '$'+json.data.net_shipping_revenue.pro;
      rows[13][5] = '$'+json.data.net_shipping_revenue.employee;
      rows[13][6] = '$'+json.data.net_shipping_revenue.warranty;
      rows[13][7] = '$'+json.data.net_shipping_revenue.expertvoice;
      rows[13][8] = '$'+json.data.net_shipping_revenue.retail;
      rows[13][9] = '$'+json.data.net_shipping_revenue.co2;

      //Net tax revenue
      rows[14][1] = '$'+json.data.net_tax_revenue.total;
      rows[14][2] = '$'+json.data.net_tax_revenue.US;
      rows[14][3] = '$'+json.data.net_tax_revenue.CA;
      rows[14][4] = '$'+json.data.net_tax_revenue.pro;
      rows[14][5] = '$'+json.data.net_tax_revenue.employee;
      rows[14][6] = '$'+json.data.net_tax_revenue.warranty;
      rows[14][7] = '$'+json.data.net_tax_revenue.expertvoice;
      rows[14][8] = '$'+json.data.net_tax_revenue.retail;
      rows[14][9] = '$'+json.data.net_tax_revenue.co2;

      //Net sales revenue
      rows[15][1] = '$'+json.data.net_sales_revenue.total;
      rows[15][2] = '$'+json.data.net_sales_revenue.US;
      rows[15][3] = '$'+json.data.net_sales_revenue.CA;
      rows[15][4] = '$'+json.data.net_sales_revenue.pro;
      rows[15][5] = '$'+json.data.net_sales_revenue.employee;
      rows[15][6] = '$'+json.data.net_sales_revenue.warranty;
      rows[15][7] = '$'+json.data.net_sales_revenue.expertvoice;
      rows[15][8] = '$'+json.data.net_sales_revenue.retail;
      rows[15][9] = '$'+json.data.net_sales_revenue.co2;

      //Net discounts
      rows[16][1] = '$'+json.data.net_discounts.total;
      rows[16][2] = '$'+json.data.net_discounts.US;
      rows[16][3] = '$'+json.data.net_discounts.CA;
      rows[16][4] = '$'+json.data.net_discounts.pro;
      rows[16][5] = '$'+json.data.net_discounts.employee;
      rows[16][6] = '$'+json.data.net_discounts.warranty;
      rows[16][7] = '$'+json.data.net_discounts.expertvoice;
      rows[16][8] = '$'+json.data.net_discounts.retail;
      rows[16][9] = '$'+json.data.net_discounts.co2;

      //Average order value, gross merchandise sales
      rows[17][1] = '$'+json.data.aov_gross_sales.total;
      rows[17][2] = '$'+json.data.aov_gross_sales.US;
      rows[17][3] = '$'+json.data.aov_gross_sales.CA;
      rows[17][4] = '$'+json.data.aov_gross_sales.pro;
      rows[17][5] = '$'+json.data.aov_gross_sales.employee;
      rows[17][6] = '$'+json.data.aov_gross_sales.warranty;
      rows[17][7] = '$'+json.data.aov_gross_sales.expertvoice;
      rows[17][8] = '$'+json.data.aov_gross_sales.retail;
      rows[17][9] = '$'+json.data.aov_gross_sales.co2;

      //Average order value, discounted merchandise
      rows[18][1] = '$'+json.data.aov_discounted_merchandise.total;
      rows[18][2] = '$'+json.data.aov_discounted_merchandise.US;
      rows[18][3] = '$'+json.data.aov_discounted_merchandise.CA;
      rows[18][4] = '$'+json.data.aov_discounted_merchandise.pro;
      rows[18][5] = '$'+json.data.aov_discounted_merchandise.employee;
      rows[18][6] = '$'+json.data.aov_discounted_merchandise.warranty;
      rows[18][7] = '$'+json.data.aov_discounted_merchandise.expertvoice;
      rows[18][8] = '$'+json.data.aov_discounted_merchandise.retail;
      rows[18][9] = '$'+json.data.aov_discounted_merchandise.co2;

      //Sale units count
      rows[19][1] = json.data.sales_units_count.total;
      rows[19][2] = json.data.sales_units_count.US;
      rows[19][3] = json.data.sales_units_count.CA;
      rows[19][4] = json.data.sales_units_count.pro;
      rows[19][5] = json.data.sales_units_count.employee;
      rows[19][6] = json.data.sales_units_count.warranty;
      rows[19][7] = json.data.sales_units_count.expertvoice;
      rows[19][8] = json.data.sales_units_count.retail;
      rows[19][9] = json.data.sales_units_count.co2;

      //Units Per Transaction
      rows[20][1] = json.data.units_per_tranasction.total;
      rows[20][2] = json.data.units_per_tranasction.US;
      rows[20][3] = json.data.units_per_tranasction.CA;
      rows[20][4] = json.data.units_per_tranasction.pro;
      rows[20][5] = json.data.units_per_tranasction.employee;
      rows[20][6] = json.data.units_per_tranasction.warranty;
      rows[20][7] = json.data.units_per_tranasction.expertvoice;
      rows[20][8] = json.data.units_per_tranasction.retail;
      rows[20][9] = json.data.units_per_tranasction.co2;

      //Net gross margin, merchandise and shipping
      rows[21][1] = '$'+json.data.gross_margin.total;
      rows[21][2] = '$'+json.data.gross_margin.US;
      rows[21][3] = '$'+json.data.gross_margin.CA;
      rows[21][4] = '$'+json.data.gross_margin.pro;
      rows[21][5] = '$'+json.data.gross_margin.employee;
      rows[21][6] = '$'+json.data.gross_margin.warranty;
      rows[21][7] = '$'+json.data.gross_margin.expertvoice;
      rows[21][8] = '$'+json.data.gross_margin.retail;
      rows[21][9] = '$'+json.data.gross_margin.co2;

      //Net product margin
      rows[22][1] = '$'+json.data.net_product_margin.total;
      rows[22][2] = '$'+json.data.net_product_margin.US;
      rows[22][3] = '$'+json.data.net_product_margin.CA;
      rows[22][4] = '$'+json.data.net_product_margin.pro;
      rows[22][5] = '$'+json.data.net_product_margin.employee;
      rows[22][6] = '$'+json.data.net_product_margin.warranty;
      rows[22][7] = '$'+json.data.net_product_margin.expertvoice;
      rows[22][8] = '$'+json.data.net_product_margin.retail;
      rows[22][9] = '$'+json.data.net_product_margin.co2;

      //order adjustments debug
      
      this.setState({
        rows: rows,
        //orderAdjustmentsArray: json.data.order_adjustment_rows,
        isRunningQuery:false,
        debugOutput:json.debug_output,
        //revertSettings: revertResponse
      }, () => {
        //console.log('should have updated: '+JSON.stringify(this.state.rows));
          //after settings?
          //console.log(JSON.stringify(this.state.settings));
          //this.setColorPickerHSLValues();
          //this.setPreviewStyles();
      });
      
    })
    // open the request with the verb and the url
    xhr.open('POST', ajaxUrl);

    // send the request
    xhr.send(JSON.stringify({ 
      action: 'get_reporting_data',
      begin: this.state.formDateBegin,
      end: this.state.formDateEnd,
      order_id: this.state.orderId,
      debug: this.state.debug
    }));

    this.setState({
      isSelectingDate:false,
      isRunningQuery:true
    },() => {
      //console.log('done');
      /*
      const timer = setTimeout(() => {
        this.setState({
          isRunningQuery:false
        });
      }, 1000);
      return () => clearTimeout(timer);
      */
    });  
  }

  
  

  render() {

    return (
      
      <AppProvider
      i18n={{
        Polaris: {
          ContextualSaveBar: {
            save: 'Save',
            discard: 'Discard',
          },
          ResourceList: {
            sortingLabel: 'Sort by',
            defaultItemSingular: 'item',
            defaultItemPlural: 'items',
            showing: 'Showing {itemsCount} {resource}',
            Item: {
              viewItem: 'View details for {itemName}',
            },
          },
          Common: {
            checkbox: 'checkbox',
          },
        },
      }}
    >
      <Frame>
        <Page
            fullWidth
            title="Reporting"
        >
          <Layout>
            <Layout.Section>
              <Card>
                <FormLayout>
                  <div className={"query-form"}>
                    <Stack>
                    <TextField 
                        label="Order ID Override" 
                        //onFocus={() => {this.showDatePicker()}} 
                        onChange={ (event) => this.changeOrderId(event) }
                        value={this.state.orderId} 
                      />
                      <TextField 
                        label="Begin Date" 
                        //onFocus={() => {this.showDatePicker()}} 
                        onChange={ (event) => this.changeBeginDate(event) }
                        value={this.state.formDateBegin} 
                      />
                      <TextField 
                        label="End Date"  
                        //onFocus={() => {this.showDatePicker()}} 
                        onChange={ (event) => this.changeEndDate(event) }
                        value={this.state.formDateEnd} 
                      />
                      <Button primary onClick={() => {this.runQuery()}} disabled={(this.state.isRunningQuery === true || !this.state.formDateBeginIsValid || !this.state.formDateEndIsValid) ? true : false}>Run Query</Button>
                      <div className="debug-container">
                        <Checkbox
                          label="Debug Output"
                          checked={this.state.debug}
                          onChange={(event) => this.toggleDebug(event)}
                        />
                      </div>
                      <div className={"running-query "+(this.state.isRunningQuery == true ? 'show' : 'hidden')}>
                        <Spinner accessibilityLabel="Loading query" size="small" />
                      </div>
                    </Stack>
                    <div
                      className={"long-query-warning " + (this.state.showLongQueryWarning ? 'show' : 'hidden')}//showLongQueryWarning
                    >Warning: Long queries may take several minutes to complete</div>
                    <div className={"date-picker " + (this.state.isSelectingDate ? 'show' : 'hidden')}>
                      <DatePicker
                        month={this.state.month}
                        year={this.state.year}
                        //onChange={setSelectedDates}
                        onMonthChange={this.handleMonthChange}
                        selected={this.state.selectedDates}
                        allowRange
                      />
                    </div>
                  </div>
                </FormLayout>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <DataTable
                  columnContentTypes={[
                    'text',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                    'text',
                  ]}
                  headings={[
                    'METRIC',
                    'Total',
                    'US only',
                    'CA only',
                    'Pro',
                    'Employee',
                    'Warranty',
                    'ExpertVoice',
                    'Retail',
                    'CO2',
                    ' ',
                  ]}
                  rows={this.state.rows}
                  //totals={['', '', '', 255, '$155,830.00']}
                />
              </Card>
              <Card>
                <div className="debug-output-container">
                  <Heading>Debugging Output</Heading>
                  <pre>{this.state.debugOutput}</pre>
                </div>
              </Card>
            </Layout.Section>
            {/*
            <Layout.Section>
              <Heading>Credit Adjustments</Heading>
              <Card>
                <DataTable
                
                  columnContentTypes={[
                    'numeric',
                    'numeric',
                    'text',
                    'numeric',
                  ]}
                  
                  headings={[
                    'Order ID',
                    'Refund ID',
                    'Kind',
                    'Amount',
                  ]}
                  rows={this.state.orderAdjustmentsArray}
                  //totals={['', '', '', 255, '$155,830.00']}
                />
              </Card>
            </Layout.Section>
            */}
          </Layout>
        </Page>
      </Frame>
    </AppProvider>
    );
  }
  

}

export default App;